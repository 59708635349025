import { NbMenuItem } from '@nebular/theme';
import { Permission, Role } from '@app/@core/enum';

export const MENU_ITEMS: NbMenuItem[] = [
  {
    title: 'pages-menu.dashboard',
    icon: 'eva eva-grid-outline',
    link: '/pages/dashboard',
    home: true,
    data: {
      permission: Permission.View,
      resource: Role.User,
    },
  },

  /*{
    title: 'pages-menu.demographics',
    icon: 'eva eva-trending-up-outline',
    link: '/pages/patient/demographics',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },*/
  {
    title: 'pages-menu.demographics',
    icon: 'eva eva-person-outline',
    link: '/pages/patient/static',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.general',
    icon: 'eva eva-pantone-outline',
    link: '/pages/patient/general',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.vitals',
    icon: 'eva eva-heart-outline',
    link: '/pages/patient/vitals',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.treatments',
    icon: 'eva eva-color-picker-outline',
    link: '/pages/patient/treatments',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
 /* {
    title: 'pages-menu.health',
    icon: 'eva eva-heart-outline',
    link: '/pages/patient/health',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.lifestyle',
    icon: 'eva eva-pantone-outline',
    link: '/pages/patient/lifestyle',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.genealogy',
    icon: 'eva eva-share-outline',
    link: '/pages/patient/genealogy',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.body',
    icon: 'eva eva-person-outline',
    link: '/pages/patient/body',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.social',
    icon: 'eva eva-people-outline',
    link: '/pages/patient/social',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.psychology',
    icon: 'eva eva-umbrella-outline',
    link: '/pages/patient/psychology',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.clinical',
    icon: 'eva eva-droplet-outline',
    link: '/pages/patient/clinical',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },
  {
    title: 'pages-menu.condition',
    icon: 'eva eva-activity-outline',
    link: '/pages/patient/condition',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },

  {
    title: 'pages-menu.consent',
    icon: 'eva eva-checkmark-circle-outline',
    link: '/pages/consent',
    data: {
      permission: Permission.View,
      resource: Role.Patient,
    },
  },*/

  {
    title: 'pages-menu.specimens',
    icon: 'eva eva-color-picker',
    link: '/pages/specimens',
    data: {
      permission: Permission.View,
      resource: Role.Biobank,
    },
  },
  {
    title: 'pages-menu.patients',
    icon: 'eva eva-people-outline',
    link: '/pages/patients',
    data: {
      permission: Permission.Edit,
      resource: Role.Patient,
    },
  },

  {
    title: 'pages-menu.users',
    icon: 'eva eva-people-outline',
    children: [
      {
        title: 'pages-menu.patients',
        link: '/pages/users/patients',
      },
      {
        title: 'pages-menu.healthcare-providers',
        link: '/pages/users/healthcare-providers',
      },
      {
        title: 'pages-menu.biobanks',
        link: '/pages/users/biobanks',
      },
      {
        title: 'pages-menu.researchers',
        link: '/pages/users/researchers',
      },
    ],
    // expanded: true,
    data: {
      permission: Permission.View,
      resource: Role.Admin,
    },
  },

  {
    title: 'pages-menu.relations',
    icon: 'eva eva-link-2-outline',
    link: '/pages/relations',
    data: {
      permission: Permission.View,
      resource: Role.Admin,
    },
  },

  // {
  //   title: 'DEMO DASHBOARDS',
  //   group: true,
  // },
  // {
  //   title: 'E-commerce',
  //   icon: 'nb-e-commerce',
  //   link: '/pages/e-commerce',
  // },
  // {
  //   title: 'IoT Dashboard',
  //   icon: 'nb-grid-a-outline',
  //   link: '/pages/iot-dashboard',
  // },
  // {
  //   title: 'FEATURES',
  //   group: true,
  // },
  // {
  //   title: 'Extra Components',
  //   icon: 'nb-star',
  //   children: [
  //     {
  //       title: 'Calendar',
  //       link: '/pages/extra-components/calendar',
  //     },
  //     {
  //       title: 'Stepper',
  //       link: '/pages/extra-components/stepper',
  //     },
  //     {
  //       title: 'List',
  //       link: '/pages/extra-components/list',
  //     },
  //     {
  //       title: 'Infinite List',
  //       link: '/pages/extra-components/infinite-list',
  //     },
  //     {
  //       title: 'Form Inputs',
  //       link: '/pages/extra-components/form-inputs',
  //     },
  //     {
  //       title: 'Accordion',
  //       link: '/pages/extra-components/accordion',
  //     },
  //     {
  //       title: 'Progress Bar',
  //       link: '/pages/extra-components/progress-bar',
  //     },
  //     {
  //       title: 'Spinner',
  //       link: '/pages/extra-components/spinner',
  //     },
  //     {
  //       title: 'Alert',
  //       link: '/pages/extra-components/alert',
  //     },
  //     {
  //       title: 'Tree',
  //       link: '/pages/extra-components/tree',
  //     },
  //     {
  //       title: 'Tabs',
  //       link: '/pages/extra-components/tabs',
  //     },
  //     {
  //       title: 'Calendar Kit',
  //       link: '/pages/extra-components/calendar-kit',
  //     },
  //     {
  //       title: 'Chat',
  //       link: '/pages/extra-components/chat',
  //     },
  //   ],
  // },
  // {
  //   title: 'Forms',
  //   icon: 'nb-compose',
  //   children: [
  //     {
  //       title: 'Form Inputs',
  //       link: '/pages/forms/inputs',
  //     },
  //     {
  //       title: 'Form Layouts',
  //       link: '/pages/forms/layouts',
  //     },
  //     {
  //       title: 'Buttons',
  //       link: '/pages/forms/buttons',
  //     },
  //     {
  //       title: 'Datepicker',
  //       link: '/pages/forms/datepicker',
  //     },
  //   ],
  // },
  // {
  //   title: 'UI Features',
  //   icon: 'nb-keypad',
  //   link: '/pages/ui-features',
  //   children: [
  //     {
  //       title: 'Grid',
  //       link: '/pages/ui-features/grid',
  //     },
  //     {
  //       title: 'Icons',
  //       link: '/pages/ui-features/icons',
  //     },
  //     {
  //       title: 'Typography',
  //       link: '/pages/ui-features/typography',
  //     },
  //     {
  //       title: 'Animated Searches',
  //       link: '/pages/ui-features/search-fields',
  //     },
  //   ],
  // },
  // {
  //   title: 'Modal & Overlays',
  //   icon: 'nb-layout-default',
  //   children: [
  //     {
  //       title: 'Dialog',
  //       link: '/pages/modal-overlays/dialog',
  //     },
  //     {
  //       title: 'Window',
  //       link: '/pages/modal-overlays/window',
  //     },
  //     {
  //       title: 'Popover',
  //       link: '/pages/modal-overlays/popover',
  //     },
  //     {
  //       title: 'Toastr',
  //       link: '/pages/modal-overlays/toastr',
  //     },
  //     {
  //       title: 'Tooltip',
  //       link: '/pages/modal-overlays/tooltip',
  //     },
  //   ],
  // },
  // {
  //   title: 'Bootstrap',
  //   icon: 'nb-gear',
  //   children: [
  //     {
  //       title: 'Form Inputs',
  //       link: '/pages/bootstrap/inputs',
  //     },
  //     {
  //       title: 'Buttons',
  //       link: '/pages/bootstrap/buttons',
  //     },
  //     {
  //       title: 'Modal',
  //       link: '/pages/bootstrap/modal',
  //     },
  //   ],
  // },
  // {
  //   title: 'Maps',
  //   icon: 'nb-location',
  //   children: [
  //     {
  //       title: 'Google Maps',
  //       link: '/pages/maps/gmaps',
  //     },
  //     {
  //       title: 'Leaflet Maps',
  //       link: '/pages/maps/leaflet',
  //     },
  //     {
  //       title: 'Bubble Maps',
  //       link: '/pages/maps/bubble',
  //     },
  //     {
  //       title: 'Search Maps',
  //       link: '/pages/maps/searchmap',
  //     },
  //   ],
  // },
  // {
  //   title: 'Charts',
  //   icon: 'nb-bar-chart',
  //   children: [
  //     {
  //       title: 'Echarts',
  //       link: '/pages/charts/echarts',
  //     },
  //     {
  //       title: 'Charts.js',
  //       link: '/pages/charts/chartjs',
  //     },
  //     {
  //       title: 'D3',
  //       link: '/pages/charts/d3',
  //     },
  //   ],
  // },
  // {
  //   title: 'Editors',
  //   icon: 'nb-title',
  //   children: [
  //     {
  //       title: 'TinyMCE',
  //       link: '/pages/editors/tinymce',
  //     },
  //     {
  //       title: 'CKEditor',
  //       link: '/pages/editors/ckeditor',
  //     },
  //   ],
  // },
  // {
  //   title: 'Tables',
  //   icon: 'nb-tables',
  //   children: [
  //     {
  //       title: 'Smart Table',
  //       link: '/pages/tables/smart-table',
  //     },
  //   ],
  // },
];
