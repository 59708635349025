import { isObservable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormlyFieldConfig, FormlyConfig } from '@ngx-formly/core';

class DisableExpressionExtension {

  postPopulate(field: FormlyFieldConfig) {
    const to = field.templateOptions || {};

    if (to.options) {
      field.form.valueChanges.subscribe(x => {
        if (isObservable(to.options)) {
          field.expressionProperties = {
            ...(field.expressionProperties), 'templateOptions.options': to.options.pipe(
              map(options => {
                options.forEach(option => {
                  option.disabled = new Function('model', 'return ' + option.disableExpression + ';')(field.model);
                });
                to.options = [...options];
                return of(options);
              }),
            ),
          };
        } else {
          let i = 0;
          const l = to.options.length;
          for (i; i < l; i++) {
            const option = to.options[i];
            if (option.disableExpression) {
              option.disabled = new Function('model', 'return ' + option.disableExpression + ';')(field.model);
            }
          }
          to.options = [...to.options];
          field.expressionProperties = { ...(field.expressionProperties), 'templateOptions.options': of(to.options) };

          // field.expressionProperties = {
          //   ...(field.expressionProperties),
          //   'templateOptions.options': of(
          //     to.options.map(option => {
          //       option.disabled = this.isOptionDisabled(option, field.model);
          //       this.formlyConfig.resolveFieldTypeRef(field).changeDetectorRef.detectChanges();
          //       return option;
          //     })
          //   ),
          // };
        }
      });
    }
  }

}

export function registerDisableExpressionExtension() {
  return {
    extensions: [{
      name: 'disableExpression',
      extension: new DisableExpressionExtension(),
    }],
  };
}
