import { isObservable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { TranslateService } from '@ngx-translate/core';

class TranslateExtension {
  constructor(private translate: TranslateService) { }
  prePopulate(field: FormlyFieldConfig) {
    const to = field.templateOptions || {};
    if (!to.translate || to._translated) {
      return;
    }

    to._translated = true;
    field.expressionProperties = {
      ...(field.expressionProperties || {}),
      'templateOptions.label': this.translate.stream(to.label),
    };

    if (to.options) {
      if (isObservable(to.options)) {
        field.expressionProperties = {
          ...(field.expressionProperties),
          'templateOptions.options': to.options.pipe(
            map(options => {
              options.forEach(option => {
                option.label = this.translate.instant(option.label);
              });
              return of(options);
            }),
          ),
        };
      } else {
        field.expressionProperties = {
          ...(field.expressionProperties),
          'templateOptions.options': of(
            to.options.map(option => {
              option.label = this.translate.instant(option.label);
              return option;
            })
          ),
        };
      }
    }
  }
}

export function registerTranslateExtension(translate: TranslateService) {
  return {
    extensions: [{
      name: 'translate',
      extension: new TranslateExtension(translate),
    }],
  };
}
