import { Component } from '@angular/core';
import { FormlyFieldInput } from '@ngx-formly/bootstrap';

@Component({
  selector: 'ngx-formly-field-input',
  template: `
    <ng-container [ngSwitch]="type">
      <span *ngSwitchCase="'date'">
        <input [formControl]="formControl" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError" [nbDatepicker]="datepicker"/>
        <nb-datepicker #datepicker></nb-datepicker>
      </span>
      <span *ngSwitchCase="'tel'">
        <international-phone-number [formControl]="formControl" [formlyAttributes]="field" [class.is-invalid]="showError"></international-phone-number>
      </span>
      <span *ngSwitchCase="'time'">
        <ngb-timepicker [formControl]="formControl" [formlyAttributes]="field" [class.is-invalid]="showError"></ngb-timepicker>
      </span>
      <div class="input-group mb-3" *ngIf="to.addonRight || to.addonLeft; else defaultInput">
        <div class="input-group-prepend" *ngIf="to.addonLeft" [ngStyle]="{cursor: to.addonLeft.onClick ? 'pointer' : 'inherit'}">
          <i class="input-group-text" [ngClass]="to.addonLeft.class" *ngIf="to.addonLeft.class"></i>
          <span *ngIf="to.addonLeft.text" class="input-group-text">{{ to.addonLeft.text }}</span>
        </div>
        <input *ngSwitchDefault [type]="type" [formControl]="formControl" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError">
        <div class="input-group-append" *ngIf="to.addonRight" [ngStyle]="{cursor: to.addonRight.onClick ? 'pointer' : 'inherit'}">
          <i class="input-group-text" [ngClass]="to.addonRight.class" *ngIf="to.addonRight.class"></i>
          <span *ngIf="to.addonRight.text" class="input-group-text">{{ to.addonRight.text }}</span>
        </div>
      </div>
      <ng-template #defaultInput>
        <input *ngSwitchDefault [type]="type" [formControl]="formControl" class="form-control" [formlyAttributes]="field" [class.is-invalid]="showError">
      </ng-template>
    </ng-container>
 `,
  styles: []
})
export class FormlyFieldInputComponent extends FormlyFieldInput {
  /**
   * @return {?}
   */
  get type() {
    return this.to.type || 'text';
  }
}
