import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ControlBase } from './control-base';

@Component({
  selector: 'ngx-dynamic-form-control',
  templateUrl: './dynamic-form-control.component.html',
  styleUrls: ['./dynamic-form-control.component.scss']
})
export class DynamicFormControlComponent {

  @Input() control: ControlBase<any>;
  @Input() form: FormGroup;
  get formControl() { return this.form.get(this.control.key); }
  get isInvalid() { return this.control.key && this.formControl && this.formControl.invalid && (this.formControl.dirty || this.formControl.touched); }
  get meetsCondition() { return this.control.condition ? this.form.get(this.control.condition.key).value == this.control.condition.value : true; }

}
