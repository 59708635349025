import { Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { ControlBase } from './control-base';

@Injectable()
export class ControlService {
  constructor() { }

  toFormGroup(controls: ControlBase<any>[]) {
    const group: any = {};

    controls.forEach(control => {
      if (control.controlType === 'button' || control.controlType === 'legend') {
        return;
      }
      group[control.key] = control.required ? new FormControl(control.value || '', Validators.required)
        : new FormControl(control.value || null);

      if (control.disabled === true) {
        group[control.key].disable();
      }
    });
    return new FormGroup(group);
  }

  validateAllFormControls(formGroup: FormGroup) {
    for (const [field, control] of Object.entries(formGroup.controls)) {
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormControls(control);
      }
    }
  }
}
