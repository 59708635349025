import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  template: `
  <div class="termsText">
  <h2>Terms and Conditions (effective date 15/04/2020)</h2>
  <p>
  By participating in Coronab.io, you agree to abide by Coronabio’s Terms and Privacy Policy. If we become aware that a member has violated the Terms, and Conditions, that member's account may be immediately and permanently suspended, without warning or other notice. The following terms constitute an agreement between you and Metabio I.K.E., the operator of www.coronab.io (the “Platform”). This agreement governs your use of the Platform, both as a casual visitor and a registered member as described below. By accessing or using the Platform, you agree to be bound by the terms of this Agreement. When you register on the Platform as a member, then in addition to the terms that are applicable to all users of the Platform, the terms that apply only to members shall apply to you too. Everyone currently or previously infected with Covid-19 is welcome to participate. 
  </p>
  <h5>
  Membership eligibility
  </h5>
  <p>
  To become a member and access the platform requires that you are either 
  </p>
  <ul>
  <li>a diagnosed patient with Covid-19</li>
  <li>a parent/legal guardian acting for such a patient who is under 18 years of age </li>
  <li>a caregiver for a patient eligible to join</li>
  <li>a guest with non-commercial reasons to participate and who agrees to respect the privacy and dignity of all participants </li>
  <li>a Metabio member or employee.</li>
  </ul>
  <p>
  You must be 18 years old or older to participate by registering as a member you represent that you are age 18 or older.
  </p>
  <h5>
  Corona-Bio does not provide any form of Medical advice
  </h5>
  <p>
  All of the material provided on the Platform, such as text, dosages, outcomes, charts, patient profiles, graphics, images, messages, forum postings, and any other material provided on the Platform are for informational purposes only and are NOT a substitute for professional medical advice or treatment for Covid-19. If you think you may have been infected with Covid-19 call your doctor or your local and national healthcare system immediately. Corona-bio does not recommend or endorse any specific tests, products, procedures, opinions, or other information that may be mentioned on the Platform. Reliance on any information provided by Corona-Bio, by persons appearing on the Platform, or by other members is solely at your own risk. Never disregard professional medical advice or delay in seeking it because of something you have read on this Platform.
  </p>
  <h5>
  Acceptable and lawful use of the Platform by members
  </h5>
  <p>
  Members shall not post or upload any information or other content on the Platform that 
  </p>
  <ul>
  <li>is false</li>
  <li>is inaccurate or misleading</li>
  <li>is obscene or indecent</li>
  <li>infringes any copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy of any party</li>
  <li>is defamatory, libelous, threatening, abusive, hateful, or contains pornography.</li>
  </ul>
  <p>
  Members shall not interfere with other members’ use and enjoyment of the Platform Members may not use the Platform to conduct any activity that is illegal or violates the rights of others, provide instructional information about illegal activities, or promote physical harm or injury against any group or individual. All members represent and warrant that the information they provided when registering as a member, and all information that they subsequently provide regarding themselves and their membership, is true and accurate and not misleading.
  </p>
  <h5>
  No interference with Platform operations
  </h5>
  <p>
  You may not use any robot, spider, scraper, or other automated means to access the Platform or content or services provided on the Platform for any purposes. You may not post content on the Platform that contains any viruses, Trojan horses, worms, time bombs, spiders, or other computer programming routines that are intended to damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information. You shall not attempt to make the Platform unavailable through denial-of-service attacks or similar means. You shall not use contact information provided by members, or collect information about our members, to facilitate the sending of unsolicited bulk communications such as SPAM or SPIM or allow others use of your membership account to take such actions.
  </p>
  <h5>
  Platform for Non-Commercial Use Only
  </h5>
  <p>
  The Platform’s content and information is for the personal use of individual members only and may not be used in connection with any commercial endeavors. Organizations, companies, and/or businesses may not become members and should not use the Platform without express consent from Metabio. Members of CoronaBio with individual commercial interests may not solicit or overtly promote their products or services. All content shared within the site is not for external use. To gain permission and access to members, please direct all media and analyst inquiries to coronabio@metab.io For all research requests direct your inquiries to coronabio@metab.io. Representatives from life sciences and insurance companies are prohibited from creating profiles in our communities. 
  </p>
  <h5>
  Privacy
  </h5>
  <p>
  You agree that you have read, understood and accept Corona-Bio's Privacy Notice. This policy explains Corona-Bio's processing of your personal information, when using the Platform, its use and exploitation.
  </p>
  <h5>
  Member Password and Login Identity
  </h5>
  <p>
  You are responsible for maintaining the confidentiality of your member password and login and you are fully responsible for all activities that occur under your password or account with or without your knowledge. If you knowingly provide your login and password information to another person, your membership privileges may be suspended temporarily or terminated. You agree to immediately notify Corona-Bio and/or Metabio of any unauthorized use of your membership password or login or any other breach of security.
  </p>
  <h5>
  Membership Termination
  </h5>
  <p>
  You agree that Corona-Bio may, with or without cause, immediately terminate your registration or limit or terminate your access to the Platform without prior notice. The following may lead to a termination of registration and use of the Platform: 
  </p>
  <ul>
  <li>breaches or violations of the terms and conditions</li>
  <li>requests by law enforcement or other government agencies</li>
  <li>your request</li>
  </ul>
  <h5>
  Modifications to these Terms and conditions
  </h5>
  <p>
  We reserve the right to modify these terms and conditions at any time, and without prior notice, by posting amended terms. We recommend that you, frequently, review these terms and conditions for updates or changes, which will have an update effective date.
  </p>
  <h5>
  Fees
  </h5>
  <p>
  Membership with Corona-Bio is FREE. 
  </p>
  <h5>
  Disclaimer of warranties
  </h5>
  <p>
  The Platform, the content and the services made available are provided on an “as is” and “as available” basis. Corona-Bio does not warrant that access to the Platform or its content or services will be uninterrupted or error-free or that defects in the Platform will be corrected.
  </p>
  <h5>
  Limitation of Liability
  </h5>
  <p>
  Under no circumstances shall Corona-Bio and/or Metabio, its partners, contributors, agents, employees, directors, or affiliates be liable for any indirect, incidental, special, or consequential damages, due to your use of the Platform or due to your reliance on any of the content contained or the services provided on the Platform.
  </p>
  <h5>
  Indemnification
  </h5>
  <p>
  You agree to indemnify and hold harmless Corona-Bio and/or Metabio, its officers, employees, agents, subsidiaries, affiliates and other partners, from and against any claims, actions or demands, liabilities and settlements including without limitation, reasonable legal and accounting fees, resulting from, or alleged to result from, your violation of this Terms and conditions
  </p>
  <h5>
  Member Notices
  </h5>
  <p>
  If you register, you agree that Corona-Bio and/or Metabio may send notices to you by email at the email address you provide when registering to become a member. 
  </p>
  <h5>
  Governing Law and Venue
  </h5>
  <p>
  This agreement shall be governed by the laws of the applicable laws of the Hellenic Republic and the European Union. All disputes arising under, or in any way connected with membership in or use of the Platform, shall be litigated exclusively in Greece and the Greek courts residing within the European Union, and in no other court or jurisdiction. You hereby submit to the jurisdiction of the Greek courts sitting in Thessaloniki, Greece.
  </p>
  <p>
  Corona-Bio and the Corona-Bio logo are trademarks of Metabio. You agree not to display or use these trademarks in any manner without prior, written permission by Metabio.
  </p>
  <p>
  For any questions or comments, or to report violations of this agreement, let us know, or contact us at:
  </p>
  </div>
  <div class="termsContact">
  <h2>Contact Information</h2>
  <p>Metabio I.K.E</p>
  <p>Agisilaou 4, Harilaou,</p>
  <p>Thessaloniki 54250,</p>
  <p>Thessaloniki 54250,</p>
  <p>Greecem</p>
  <p><b>Telephone:</b> +302310304034</p>
  <p><b>Data Protection Officer:</b> dpo@metab.io</p>
</div>
  `,
  styleUrls: ['terms-window.component.scss'],
})
export class TermsWindowComponent {
  constructor(public windowRef: NbWindowRef) {}

  close() {
    this.windowRef.close();
  }
}
