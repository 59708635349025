export enum Condition {
  CoronaNonHospitalised = 1,
  CoronaDischarged
  // MultipleMyeloma = 1,
  // BreastCancer,
  // Alzheimer,
  // BipolarDisorder,
  // Schizophrenia,
  // ProstateCancer,
  // CysticFibrosis,
  // AmyotrophicLateralSclerosis,
  // SystemicSclerosis,
  // MultpleScrerosis,
  // HepatitisC,
  // Asthma,
  // Autism,
  // MuscularDystrophy,
  // RheumatoidArthritis,
  // Parkinson,
  // Diabetes,
}
