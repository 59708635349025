import { Component } from '@angular/core';
import { NbWindowService  } from '@nebular/theme';
import { PrivacyWindowComponent } from '../../../pages/modal-overlays/window/privacy-window/privacy-window.component';
import { AboutWindowComponent } from '../../../pages/modal-overlays/window/about-window/about-window.component';
import { FaqWindowComponent } from '../../../pages/modal-overlays/window/faq-window/faq-window.component';

@Component({
  selector: 'ngx-privacy-footer',
  styleUrls: ['./privacy-footer.component.scss'],
  templateUrl: './privacy-footer.component.html'
})
export class PrivacyFooterComponent {
    constructor(
        private windowService: NbWindowService,
      ) {
      }
    openPrivacyWindow()
    {
        this.windowService.open(PrivacyWindowComponent, { title: `Privacy Policy` });
    }
    openAboutWindow()
    {
        this.windowService.open(AboutWindowComponent, { title: `About us` });
    }
    openFaqWindow()
    {
        this.windowService.open(FaqWindowComponent, { title: `Faq` });
    }
}
