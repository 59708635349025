import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { takeWhile, pairwise, startWith } from 'rxjs/operators';
import { ControlService } from './dynamic-form-control/control.service';
import { ControlBase } from './dynamic-form-control/control-base';

@Component({
  selector: 'ngx-dynamic-form',
  templateUrl: './dynamic-form.component.html',
  styleUrls: ['./dynamic-form.component.scss'],
  providers: [ControlService]
})
export class DynamicFormComponent implements OnInit, OnDestroy {

  private alive = true;

  @Input() controls: any[] = [];
  @Output() changed = new EventEmitter<any>();
  @Output() submitted = new EventEmitter<any>();
  form: FormGroup;
  formControls: ControlBase<any>[] = [];

  constructor(private controlService: ControlService) { }

  ngOnInit() {
    this.controls.forEach(control => {
      if (control instanceof Array) {
        control.forEach(element => {
          this.formControls = this.formControls.concat(element);
        });
      } else {
        this.formControls.push(control);
      }
    });
    this.form = this.controlService.toFormGroup(this.formControls);
    this.onChange();
  }

  ngOnDestroy() {
    this.alive = false;
  }

  onChange(): void {
    this.formControls.forEach(control => {
      if (control.key && this.form.get(control.key)) {
        this.form.get(control.key).valueChanges
          .pipe(takeWhile(() => this.alive))
          .pipe(startWith(this.form.get(control.key).value), pairwise())
          .subscribe(([prev, next]: [any, any]) => {
            this.changed.emit({ key: control.key, value: { prev: prev || [], next: next || [] } });
          });
      }
    });
  }

  onSubmit() {
    this.submitted.emit(this.form.value);
  }

  validateAllFormControls() {
    this.controlService.validateAllFormControls(this.form);
  }
}
