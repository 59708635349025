import { Component, ChangeDetectionStrategy, Input, HostBinding } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-page-tabs',
  templateUrl: './page-tabs.component.html',
  styleUrls: ['./page-tabs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PageTabsComponent {

  items: any[];

  @Input()
  set tabs(value) {
    this.items = value || [];
  }

  @HostBinding('class.horizontal')
  isHorizontal = false;
  @Input()
  set horizontal(value) {
    this.isHorizontal = value !== 'false' && value !== false;
  }

  constructor(private activatedRoute: ActivatedRoute) {
  }

}
