export enum OrganizationType {
  HealthcareProvider = 'prov',
  HospitalDepartment = 'dept',
  OrganizationalTeam = 'team',
  Government = 'govt',
  InsuranceCompany = 'ins',
  EducationalInstitute = 'edu',
  ReligiousInstitution = 'reli',
  ClinicalResearchSponsor = 'crs',
  CommunityGroup = 'cg',
  BusinessCorporation = 'bus',
  Other = 'other',
}
