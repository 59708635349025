import { Component, Input, OnInit } from '@angular/core';

import { NbMenuService, NbSidebarService } from '@nebular/theme';
import { NbAuthOAuth2JWTToken, NbAuthService } from '@nebular/auth';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from '@app/@core/utils';
import { LayoutService } from '@app/@core/utils';
import { filter, map } from 'rxjs/operators';
import { PatientService } from '@app/@core/data';
import * as moment from 'moment';
import { MENU_ITEMS } from '@app/pages/pages-menu';



@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {

  private menu = MENU_ITEMS;
  @Input() position = 'normal';
  currentFlag:String;
  user: any;
  languages:any;
  patient: any;
  userMenu = [{ title: this.translate.instant('header.logout'), link: '/auth/logout' }];

  constructor(
    private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private analyticsService: AnalyticsService,
    private layoutService: LayoutService,
    private authService: NbAuthService,
    private translate: TranslateService,
    private patientService: PatientService,
  ) { }

  ngOnInit() {
    this.menuService.getSelectedItem().subscribe(res => {
    })
    this.patientService.read().subscribe(res => {
      if (res && res.data) {
        this.patient = res.data;
        try {
          this.currentFlag=this.patient.communication[0].language.coding[0].code+".png";
          this.translate.use(this.patient.communication[0].language.coding[0].code);
          const lang = this.patient.communication[0].language.coding[0].code;
          // fix for menu
          this.changeMenuLanguage(lang);
          // this.sendLanguage.emit(lang);
          
          //
        } catch (err) {
          this.currentFlag = "en.png";
          this.translate.use('en');
        }
      }
      else
      {
        this.currentFlag="en.png";
        this.translate.use('en');
      }
    });
    
    this.authService.onTokenChange().subscribe((token: NbAuthOAuth2JWTToken) => {

      if (token.isValid()) {
        // here we receive a payload from the token and assign it to our `user` variable
        this.user = { name: token.getAccessTokenPayload().sub };
        this.languages = [{ title: 'Bulgarian' },{ title: 'English' },{ title: 'Greek' }];
      }
      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'languages-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        var code='';
        switch(title){
          case 'English':
            code='en'
            break;
          case 'Greek':
            code='el'
            break;
          case 'Bulgarian':
            code='bg'
            break;
        }
        this.currentFlag=code+".png";
        this.translate.use(code);
        var lang={
          coding:[
            {
              code:code,
              display:title,
              system:"urn:metab.io:user:language",
              userSelected:false,
              version:"v1"
            }
          ],
          text:title
        };
        
        this.patient.communication=[{language:lang,preferred:true}];
        this.patientService.update(this.patient).subscribe(res => {
          // change here too 
          this.changeMenuLanguage(lang.coding[0].code);
        });
      });

    });
  }

  changeMenuLanguage(lang) {
    // window.location.reload();
    this.resetMenu();
    this.translate.use(lang).subscribe(res => {
      Object.values(this.menu).forEach(value => {
        value.title = this.translate.instant(value.title);
      });
    });
  }

  private resetMenu() {
    for (let i = 0; i < this.menu.length; i++) {
      if (this.menu[i].link === '/pages/dashboard') {
        this.menu[i].title = 'pages-menu.dashboard';
      } else if (this.menu[i].link === '/pages/patient/static') {
        this.menu[i].title = 'pages-menu.demographics';
      } else if (this.menu[i].link === '/pages/patient/general') {
        this.menu[i].title = 'pages-menu.general';
      } else if (this.menu[i].link === '/pages/patient/vitals') {
        this.menu[i].title = 'pages-menu.vitals';
      } else if (this.menu[i].link === '/pages/patient/treatments') {
        this.menu[i].title = 'pages-menu.treatments';
      }
    }
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  goToHome() {
    this.menuService.navigateHome();
  }

  startSearch() {
    this.analyticsService.trackEvent('startSearch');
  }
}
