import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  template: `
  <div class="aboutText">
  <h2>About us</h2>
  <p>
  Corona-Bio: Register, Track and Share your Covid-19 symptoms with Medical Research Teams
  </p>
  <p>
  Metabio is proud to announce public access to Corona-Bio, our Covid-19 symptom platform. Patients with confirmed diagnoses can record their symptoms daily, along with several health-related factors, diet, and more. The platform is completely secure, uses the highest industry-standard software to capture and harmonize patient data and GDPR compliant.
  </p>
  <p>
    <b>All patient information collected on the Corona-Bio platform is offered for free to public health organizations, institutions or public health authorities dealing with Covid-19.</b>
  </p>
  <p>
  Corona-Bio Platform is not an app but a system utilizing HL7, Open – EHR protocols, methodology and technology produced over years of our own research and advised by renowned and credible scientists from the Medical, Pharmaceutical and Biobanking market and sectors. 
  </p>
  <p>
  Corona-Bio is currently in English, the universal research language, but is going to be multilingual in the near future.
  </p>
  </div>
  `,
  styleUrls: ['about-window.component.scss'],
})
export class AboutWindowComponent {
  constructor(public windowRef: NbWindowRef) {}

  close() {
    this.windowRef.close();
  }
}
