import { Component } from '@angular/core';

@Component({
  selector: 'ngx-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">{{ 'footer.created-by' | translate }} <b><a href="https://mindmed.gr" target="_blank">MindMed</a></b></span>
    <div class="socials">
      <!-- <a href="#" target="_blank" class="ion ion-social-github"></a> -->
      <a href="https://www.facebook.com/metabio2015" target="_blank" class="ion ion-social-facebook"></a>
      <a href="https://twitter.com/metabio1/" target="_blank" class="ion ion-social-twitter"></a>
      <a href="https://www.linkedin.com/company/metabio" target="_blank" class="ion ion-social-linkedin"></a>
    </div>
  `,
})
export class FooterComponent {
}
