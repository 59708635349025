import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { tap } from 'rxjs/operators';
import { NbAuthService } from '@nebular/auth';
import { NbAccessChecker } from '@nebular/security';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: NbAuthService,
    private accessChecker: NbAccessChecker,
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean> {
    return this.authService.isAuthenticated()
      .pipe(tap(authenticated => {
        if (!authenticated) {
          this.router.navigate(['auth/login'], {
            queryParams: {
              redirect: state.url
            }
          });
          return false;
        } else {
          // access control
          if (route.data.permission && route.data.resource) {
            return this.accessChecker.isGranted(route.data.permission, route.data.resource)
              .subscribe(granted => {
                if (!granted) {
                  this.router.navigate(['/'], {
                    queryParams: {
                      redirect: state.url
                    }
                  });
                  return false;
                }
                return true;
              });
          }
          // no access control so return true
          return true;
        }
      }));
  }
}
