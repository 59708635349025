import { Component, OnInit, ViewChild, ElementRef, NgZone, Renderer2, forwardRef, Input } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
import { Place } from '@app/@core/interface/Place';
import { AddressComponent } from '@app/@core/enum/AddressComponent';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'ngx-places-autocomplete',
  templateUrl: './places-autocomplete.component.html',
  styleUrls: ['./places-autocomplete.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => PlacesAutocompleteComponent),
    multi: true,
  }]
})
export class PlacesAutocompleteComponent implements OnInit, ControlValueAccessor {

  value: any;
  @Input() id: string;
  @Input() placeholder: string = '';
  @ViewChild('placesautocomplete', { static: true }) private autocompleteElementRef: ElementRef;

  private place: Place = {};
  private _onChange = (_: any) => { };
  private _onTouched = () => { };

  constructor(
    private _renderer: Renderer2,
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,
  ) { }

  ngOnInit() {
    this.initAutocomplete();
  }

  writeValue(obj: any): void {
    this.value = obj;
  }
  registerOnChange(fn: any): void {
    this._onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this._renderer.setProperty(this.autocompleteElementRef.nativeElement, 'disabled', isDisabled);
  }

  onChange(event: any) {
    if (!event.target.value.formatted_address) {
      event.target.value = '';
    }
    this._onChange(event.target.value);
  }
  onKeyup(event: any) {
    this._onChange(event.target.value);
  }
  onBlur(event: any) {
    this._onTouched();
  }

  private initAutocomplete() {
    // load Places Autocomplete
    this.mapsAPILoader.load().then(() => {
      const autocomplete = new google.maps.places.Autocomplete(
        this.autocompleteElementRef.nativeElement,
        { types: ['geocode'] },
      );

      // Avoid paying for data that you don't need by restricting the set of
      // place fields that are returned to just the address components.
      (autocomplete as any).setFields([/*'geometry', */'formatted_address', 'address_component']);

      autocomplete.addListener('place_changed', () => {
        this.ngZone.run(() => {
          // get the place result
          const place: google.maps.places.PlaceResult = autocomplete.getPlace();

          // verify result
          // if (place.geometry === undefined || place.geometry === null) {
          //   return;
          // }

          // this.place.latitude = place.geometry.location.lat();
          // this.place.longitude = place.geometry.location.lng();

          // if (place.formatted_address) {
          //   this.place.formatted_address = place.formatted_address;
          // }

          if (place.formatted_address === undefined || place.formatted_address === null) {
            return;
          }

          this.place.formatted_address = place.formatted_address;

          if (place.address_components && place.address_components.length) {
            // Get each component of the address from the place details,
            // and then fill-in the corresponding field on the form.
            for (let i = 0; i < place.address_components.length; i++) {
              const addressType = place.address_components[i].types[0];
              if (AddressComponent[addressType]) {
                const val = place.address_components[i][AddressComponent[addressType]];
                this.place[addressType] = val;
              }
            }
          }

          this.value = this.place;
          this._onChange(this.value);
        });
      });
    });
  }

}
