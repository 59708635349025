import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { PeriodsService } from './periods.service';
import { TrafficBarData, TrafficBar } from '../data/traffic-bar';

@Injectable()
export class TrafficBarService extends TrafficBarData {

  private data = {};

  constructor(private period: PeriodsService) {
    super();
    this.data = {
      week: this.getDataForWeekPeriod(),
      month: this.getDataForMonthPeriod(),
      year: this.getDataForYearPeriod(),
    };
  }

  getDataForWeekPeriod(): TrafficBar {
    return {
      data: [86, 105, 74, 83, 89, 95, 75],
      labels: this.period.getWeeks(),
      formatter: '{c0} BPM',
    };
  }

  getDataForMonthPeriod(): TrafficBar {
    return {
      data: [80, 88, 93, 84, 78, 92, 80, 93, 77, 99, 88, 79],
      labels: this.period.getMonths(),
      formatter: '{c0} BPM',
    };
  }

  getDataForYearPeriod(): TrafficBar {
    return {
      data: [93, 78, 84, 90, 80, 73, 95, 79, 881],
      labels: this.period.getYears(),
      formatter: '{c0} BPM',
    };
  }

  getTrafficBarData(period: string): Observable<TrafficBar> {
    return observableOf(this.data[period]);
  }
}
