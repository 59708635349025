import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { InternationalPhoneNumberModule } from 'ngx-international-phone-number';
import { AgmCoreModule } from '@agm/core';
import { FullCalendarModule } from '@fullcalendar/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormlyModule, FORMLY_CONFIG } from '@ngx-formly/core';
import { ɵa } from '@ngx-formly/core/select'; // this is the FormlySelectOptionsPipe
import { FormlyFieldCheckbox, FormlyFieldMultiCheckbox, FormlyFieldRadio, FormlyFieldTextArea } from '@ngx-formly/bootstrap';
import { PrivacyWindowComponent } from '../pages/modal-overlays/window/privacy-window/privacy-window.component';
import { TermsWindowComponent } from '../pages/modal-overlays/window/terms-window/terms-window.component';
import { AboutWindowComponent } from '../pages/modal-overlays/window/about-window/about-window.component';
import { FaqWindowComponent } from '../pages/modal-overlays/window/faq-window/faq-window.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import {
  NbTreeGridModule,
  NbActionsModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbRouteTabsetModule,
  NbSearchModule,
  NbSidebarModule,
  NbTabsetModule,
  NbThemeModule,
  NbUserModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbStepperComponent,
  NbButtonModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbListModule,
  NbToastrModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbChatModule,
  NbTooltipModule,
  NbCalendarKitModule,
} from '@nebular/theme';

import { NbSecurityModule } from '@nebular/security';
import {
  FooterComponent,
  HeaderComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  TinyMCEComponent,
  ThemeSwitcherListComponent,
  ToggleSettingsButtonComponent,
  PasswordUpdateComponent,
  DynamicFormComponent,
  DynamicFormControlComponent,
  PlacesAutocompleteComponent,
  CellDateTimeRenderComponent,
  TimelineComponent,
  PrivacyFooterComponent,
  PageTabsComponent,
  FormlyWrapperFormFieldComponent,
  FormlyFieldInputComponent,
  FormlyFieldSelectComponent,
  registerTranslateExtension,
  registerDisableExpressionExtension,
} from './components';
import {
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
} from './pipes';
import {
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { environment } from '@env/environment';

const BASE_MODULES = [CommonModule, FormsModule, ReactiveFormsModule, RouterModule];

const NB_MODULES = [
  Ng2SmartTableModule,
  NbTreeGridModule,
  NbCardModule,
  NbLayoutModule,
  NbTabsetModule,
  NbRouteTabsetModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbCheckboxModule,
  NbPopoverModule,
  NbContextMenuModule,
  NgbModule,
  NbSecurityModule, // *nbIsGranted directive,
  NbProgressBarModule,
  NbCalendarModule,
  NbCalendarRangeModule,
  NbStepperModule,
  NbButtonModule,
  NbListModule,
  NbToastrModule,
  NbInputModule,
  NbAccordionModule,
  NbDatepickerModule,
  NbDialogModule,
  NbWindowModule,
  NbAlertModule,
  NbSpinnerModule,
  NbRadioModule,
  NbSelectModule,
  NbChatModule,
  NbTooltipModule,
  NbCalendarKitModule,
];

const COMPONENTS = [
  SwitcherComponent,
  LayoutDirectionSwitcherComponent,
  ThemeSwitcherComponent,
  ThemeSwitcherListComponent,
  HeaderComponent,
  FooterComponent,
  TermsWindowComponent,
  PrivacyWindowComponent,
  AboutWindowComponent,
  FaqWindowComponent,
  SearchInputComponent,
  ThemeSettingsComponent,
  TinyMCEComponent,
  OneColumnLayoutComponent,
  SampleLayoutComponent,
  ThreeColumnsLayoutComponent,
  TwoColumnsLayoutComponent,
  ToggleSettingsButtonComponent,
  PasswordUpdateComponent,
  DynamicFormComponent,
  DynamicFormControlComponent,
  PlacesAutocompleteComponent,
  CellDateTimeRenderComponent,
  TimelineComponent,
  PrivacyFooterComponent,
  PageTabsComponent,
  FormlyWrapperFormFieldComponent,
  FormlyFieldInputComponent,
  FormlyFieldSelectComponent,
  FormlyFieldCheckbox,
  FormlyFieldMultiCheckbox,
  FormlyFieldRadio,
  FormlyFieldTextArea,
];

const CUSTOM_MODULES = [
  TranslateModule,
  AgmCoreModule,
  InternationalPhoneNumberModule,
  FullCalendarModule,
  NgSelectModule,
  FormlyModule,
];

const ENTRY_COMPONENTS = [
  ThemeSwitcherListComponent,
  FooterComponent,
  PrivacyFooterComponent,
  TermsWindowComponent,
  PrivacyWindowComponent,
  AboutWindowComponent,
  FaqWindowComponent
];

const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  EvaIconsPipe,
  ɵa,
];

const NB_THEME_PROVIDERS = [
  ...NbThemeModule.forRoot(
    {
      name: DEFAULT_THEME.name,
    },
    [DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME],
  ).providers,
  ...NbSidebarModule.forRoot().providers,
  ...NbMenuModule.forRoot().providers,
  ...NbDatepickerModule.forRoot().providers,
  ...NbDialogModule.forRoot().providers,
  ...NbWindowModule.forRoot().providers,
  ...NbToastrModule.forRoot().providers,
  ...NbChatModule.forRoot({
    messageGoogleMapKey: 'AIzaSyA_wNuCzia92MAmdLRzmqitRGvCF7wCZPY',
  }).providers,
];

const CUSTOM_PROVIDERS = [
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient]
    }
  }).providers,
  AgmCoreModule.forRoot(environment.agmConfig).providers,
  FormlyModule.forRoot({
    wrappers: [
      { name: 'form-field', component: FormlyWrapperFormFieldComponent },
    ],
    types: [
      { name: 'input', component: FormlyFieldInputComponent, wrappers: ['form-field'] },
      { name: 'select', component: FormlyFieldSelectComponent, wrappers: ['form-field'] },
      { name: 'checkbox', component: FormlyFieldCheckbox, wrappers: ['form-field'] },
      { name: 'multicheckbox', component: FormlyFieldMultiCheckbox, wrappers: ['form-field'] },
      { name: 'radio', component: FormlyFieldRadio, wrappers: ['form-field'] },
      { name: 'textarea', component: FormlyFieldTextArea, wrappers: ['form-field'] },
    ],
  }).providers,
  { provide: FORMLY_CONFIG, multi: true, useFactory: registerTranslateExtension, deps: [TranslateService] },
  { provide: FORMLY_CONFIG, multi: true, useFactory: registerDisableExpressionExtension, deps: [] },
];

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/');
}

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...CUSTOM_MODULES],
  exports: [...BASE_MODULES, ...NB_MODULES, ...CUSTOM_MODULES, ...COMPONENTS, ...PIPES],
  declarations: [...COMPONENTS, ...PIPES],
  entryComponents: [...ENTRY_COMPONENTS],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: ThemeModule,
      providers: [...NB_THEME_PROVIDERS, ...CUSTOM_PROVIDERS],
    } as ModuleWithProviders;
  }
}
