import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AnalyticsService } from './@core/utils/analytics.service';
import {Router, NavigationEnd} from '@angular/router';
import {filter} from 'rxjs/operators'

declare var gtag: Function;
@Component({
  selector: 'ngx-app',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {

  constructor(
    private analytics: AnalyticsService, 
    public translate: TranslateService,
    router:Router
    ) {
      const navEndEvents = router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      );
      navEndEvents.subscribe((event: NavigationEnd) =>{
        console.log(event.urlAfterRedirects)
        gtag('config', 'UA-167132701-1',
        {
          'page_path':event.urlAfterRedirects
        });
      });
  }

  ngOnInit(): void {
    this.translate.setDefaultLang('en');
    this.translate.use('en');
    this.analytics.trackPageViews();
  }
}
