import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ngx-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() source: { groups: any[], entries: any[] };
  @Output() entrySelect = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {
  }

  toggleGroup(group) {
    group.active = !group.active;
  }

  selectEntry(entry) {
    this.entrySelect.emit(entry);
  }
}
