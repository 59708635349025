import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { DefaultResponse } from '../interface/DefaultResponse';
import { catchError, delay } from 'rxjs/operators';
import { environment } from '@env/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class ObservationService {

  private apiUrl = environment.api.endpoints.base + 'fhir/observation';  // URL to web api

  constructor(private http: HttpClient) { }

  /** POST: create new on the server */
  create(arg: fhir.Observation): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Observation>> {
    return this.http.post<DefaultResponse<fhir.OperationOutcome | fhir.Observation>>(this.apiUrl, arg, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Observation>>('createObservation'))
    );
  }

  /** GET: find by id */
  read(id?: string): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Observation>> {
    let url: string;
    if (id) {
      url = `${this.apiUrl}/${id}`;
    } else {
      url = `${this.apiUrl}/me`;
    }
    return this.http.get<DefaultResponse<fhir.OperationOutcome | fhir.Observation>>(url).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Observation>>(`readObservation id=${id}`))
    );
  }

  /** PUT: update on the server */
  update(arg: fhir.Observation): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Observation>> {
    const url = `${this.apiUrl}/${arg.id}`;
    return this.http.put<DefaultResponse<fhir.Observation>>(url, arg, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.Observation>>('updateObservation'))
    );
  }

  /** PATCH: update on the server */
  patch(id: string, patch: any): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Observation>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.patch<DefaultResponse<fhir.Observation>>(url, patch, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.Observation>>('patchObservation'))
    );
  }

  /** DELETE: delete from the server */
  delete(arg: fhir.Observation | string): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Observation>> {
    const id = typeof arg === 'string' ? arg : arg.id;
    const url = `${this.apiUrl}/${id}`;

    return this.http.delete<DefaultResponse<fhir.OperationOutcome | fhir.Observation>>(url, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Observation>>('deleteObservation'))
    );
  }

  /* GET all or containing search params */
  search(searchParams?: any): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Bundle & { type: 'searchset' } | any>> {
    const url = searchParams ? `${this.apiUrl}/?searchParams=${JSON.stringify(searchParams)}` : this.apiUrl;
    return this.http.get<DefaultResponse<fhir.OperationOutcome | fhir.Bundle & { type: 'searchset' }>>(url).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Bundle & { type: 'searchset' }>>('searchObservations'))
    );
  }

  /* GET all Observation options for populating select elements */
  loadSymptomOptions(): Observable<any[]> {
    return this.http
      .get<any[]>('assets/data/symptoms.json')
      .pipe(
        delay(500),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
