import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  template: `
  <div class="privacyText">
  <h2>Privacy Notice</h2>
  <p>
    Corona-Bio as part of Metabio IKE, is based in Greece within the European Union, where the “General Data Protection Regulation” (GDPR) applies to our processing of your personal data, regardless you live in Europe or not.
  </p>
  <p>
    Our work in Metabio and Corona-Bio and our processes involve processing your, sensitive personal data; information about your health (symptoms, medicines, therapies), information about you (demographics, lifestyle) as well as data shared by your phone or personal computer (IP address, location). This data is processed by Metabio, Corona-Bio and all our affiliate entities, so as to better understand the symptoms of COVID-19, the epidemiology of COVID-19, progress current and future scientific research into the links between COVID-19 carrier’s health and their response to the infection. We freely provide this data to help all national health systems, towards the support patients and the society. The legal basis for this processing comes from you by setting your consent status, through our consent form. We cannot process your sensitive personal data, if you do not consent and in the event, you withdraw your consent; we cannot allow you to start or continue to use the platform. Your data can be shared with people and institutions and legal entities, private or state, doing health and/or medical research, such as, Hospitals, National Health Services, Universities, Health charities, Biobanks, Pharmaceutical companies, Biotechnology companies, other research institutions.
  </p>
  <p>
    Depending on your consent status we provide the appropriate coding method to replace your personal details when we share this with researchers outside our legal entity. We share data with researchers within the EU and also export it to countries outside the EU such as the USA, which have different sets of rules on data protection that may or may not protect your data in the same way as, or as well as, we do under GDPR. We have your permission to do this, as far as you consent to it and your consent is not withdrawn. We do not set any time limit on the storage of your sensitive personal data, but those will be kept according to the highest security protocols, that will be regularly reviewed. If and when you wish us to stop processing your sensitive personal data, you may withdraw your consent by emailing us at coronabio@metab.io. When your consent is withdrawn, all your sensitive personal data we hold about you will be deleted.
  </p>
  <p>
    We also process your hother personal data, such as your contact information for security reasons and privacy verification purposes, letting you know of any results on research and medicine advancements on COVID-19 performed, including your data.  We do not send any marketing emails and we will not sell your contact information to third-parties. We will keep your contact information for no more than 30 years, then we delete it; some parts of your information will not be deleted for liability reasons.
  </p>
  <p>
    We collaborate with third parties to process some or all of your personal data and when we allow them access to your data, we do not permit them to use it for their own purposes, but only for those you consented to on your consent status form. With have with its of our current, but also any future processors, a contract that requires them only to process the data on our instructions and to take proper care in using it. 
  </p>
  <p>
    Your rights under the GDPR that are also free of charge, include the right to access your personal information, the right to require us to correct any mistakes in your information, the right to require the erasure of personal information, the right to receive your personal information in a structured format, the right to transmit those data to third parties, the right to object to decisions being taken by automated means which produce legal effects concerning or affecting you, the right to object in to our continued processing of your personal data, the right to lodge a complaint with all proper supervisory authorities within the EU and the right to restrict our processing of your personal data. For further information on each of your rights and the circumstances in which they apply, see the individuals rights section under the General Data Protection Regulation. You can exercise any of those rights via email, telephone or post to our data protection officer using the contact details given below.
  </p>
</div>
<div class="privacyContact">
  <h2>Contact Information</h2>
  <p>Metabio I.K.E</p>
  <p>Agisilaou 4, Harilaou,</p>
  <p>Thessaloniki 54250,</p>
  <p>Thessaloniki 54250,</p>
  <p>Greecem</p>
  <p><b>Telephone:</b> +302310304034</p>
  <p><b>Data Protection Officer:</b> dpo@metab.io</p>
</div>
  `,
  styleUrls: ['privacy-window.component.scss'],
})
export class PrivacyWindowComponent {
  constructor(public windowRef: NbWindowRef) {}

  close() {
    this.windowRef.close();
  }
}
