export enum Color {
  Medication = '#87c9fb',
  AllergyIntolerance = '#37a5cc',
  Condition = '#53a831',
  Immunization = '#cad403',
  Result = '#f7d33d',
  Procedure = '#e86328',
  Appointment = '#EA80FC',
  EpisodeOfCare = '#9FA8DA',

  Observation = '#007bff',
}
