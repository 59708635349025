import { Component } from '@angular/core';
import { NbWindowRef } from '@nebular/theme';

@Component({
  template: `
  <div class="faqText">
    <h2>Frequently Asked Questions</h2>
    <h5>
      How does it work?
    </h5>
    <p>
    Patients open their account and sign an electronic consent form. You will be required to provide some personal information and certain data about your diagnosis. Once registered, you can begin daily recording of your symptoms and any other actions you think are relevant to your health status.
    </p>
    <h5>
    How we can help eliminate the disease?
    </h5>
    <p>
    Covid-19, the pandemic infectious disease, continues to spread globally via person-to-person contact. Due to social distancing and quarantine measures employed in many countries, a great deal of information (including actual number of cases) goes undetected and unreported.
    </p>
    <p>
    Patients that are tested for the virus and have a clinical data record, are usually those with severe symptoms and bad outcomes. The majority of people who may have mild symptoms and have not visited a hospital, or who have mild symptoms and are positive for the virus, are typically isolated at home. For these patients, very little information about the disease outcome is available. In this case, Citizen Science can help by collecting more detailed data, information that may be the key for disease prevention and the enhancement of public health surveillance. By recording detailed information associated with the disease, we can answer questions like: how long does the infection last for each patient?
What are the range of daily symptoms?
Are there delays between the infection and the detection of the virus in the body?
Why there is such a variability in the clinical cases and the disease outcomes?
    </p>
    <p>
    Information technology instruments for data collection have many benefits, as they can collect in real time large amounts of data records from multiple sources, in this case the most valuable being the patients themselves. In addition, they can help in the management of the disease outbreak, in collaboration with healthcare facilities and research centers, by connecting infected people in one platform. Everyone that participates in this program can record the daily parameters recommended from the system. These data will be collected, monitored and screened in real-time for further analysis by healthcare providers and researchers. They will have access to a large volume of data that is so far missing, and thus can inform and enhance hypotheses and proceed with new approaches for therapy.
    </p>
    <p>
    Corona-Bio’s main purpose is to aid the development of new healthcare strategies to combat the coronavirus pandemic and to improve person-centered care, by recording as many cases as possible, including non-hospitalized patients who are positive for the virus with mild, moderate or no symptoms. Self-reported data from these patients, and their disease progression, will aid in better understanding the variable clinical cases, their etiology and the unique disease progression of some groups of individuals from different demographic areas. The data will be used only for research purposes, with all required security protocols, for as long as needed. Additionally, users can observe the data analysis process related to the clinical course of the Covid-19.
    </p>
    <p>
    In the struggle to deal with all the effects of coronavirus pandemic, solidarity and cooperation are the keys to achieving better results in the faster elimination of the disease, the development of effective treatment for each patient, and the prevention of future worldwide outbreaks.
    </p>
    <h5>
    What does the e-consent do?
    </h5>
    <p>
    When you first open your profile, you will be asked to sign an electronic consent form. This document allows us to share your data with medical researchers and healthcare institutions for research purposes. The e-consent is similar to what you might sign when going to a doctor’s appointment, or having blood drawn for a lab test.
    </p>
    <h5>
    Can I share my profile and what I record on Corona-Bio with my Doctor?
    </h5>
    <p>
    Yes, you can either review your recordings via the interphase, or ask Metabio, via email, for the complete set of your data.
    </p>
    <h5>
    Is my identity protected?
    </h5>
    <p>
    Yes – your identity is completely secure and anonymous. Metabio and all its affiliate entities are GDPR compliant. 
    </p>
    <h5>
    How is Corona-Bio different from other trackers?
    </h5>
    <p>
    Our solution is not an app but a system utilizing HL7, Open – EHR protocols, methodology and technology produced over years of our own research and advised by renowned and credible scientists from the Medical, Pharmaceutical and Biobanking market and sectors. Corona-Bio being part of Metabio can not only provide to the research community the accumulated information but accompany it with human biospecimens, through its affiliations and biobank network.  
    </p>
    <h5>
    How will the system record my results?
    </h5>
    <p>
    Corona-Bio uses a combination of industry-standard software and protocols to ensure your information is protected, harmonized and standardized. This way, the medical community can use your data to help their research into Covid-19.
    </p>
    <h5>
    What languages are available?
    </h5>
    <p>
    CORONA-BIO is currently in English, the universal research language, but is going to be multilingual in the near future.
    </p>
    <h5>
    What will my data be used for?
    </h5>
    <p>
    As a patient, you have vital information that can help the medical community understand and fight Covid-19. How you are feeling, what medicines bring relief, or when certain symptoms change, are all important indicators of how the virus acts on the human body. The symptoms and other statistics that you record on CORONA-BIO will help doctors, medical researchers, epidemiologists and other healthcare professionals as they race to find therapies, vaccines, and a cure.
    </p>
  </div>
  `,
  styleUrls: ['faq-window.component.scss'],
})
export class FaqWindowComponent {
  constructor(public windowRef: NbWindowRef) {}

  close() {
    this.windowRef.close();
  }
}
