import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { DefaultResponse } from '../interface/DefaultResponse';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class OrganizationService {

  private apiUrl = environment.api.endpoints.base + 'fhir/organization';  // URL to web api

  constructor(private http: HttpClient) { }

  /** POST: create new on the server */
  create(arg: fhir.Organization): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Organization>> {
    return this.http.post<DefaultResponse<fhir.OperationOutcome | fhir.Organization>>(this.apiUrl, arg, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Organization>>('createOrganization'))
    );
  }

  /** GET: find by id */
  read(id?: string): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Organization>> {
    let url: string;
    if (id) {
      url = `${this.apiUrl}/${id}`;
    } else {
      url = `${this.apiUrl}/me`;
    }
    return this.http.get<DefaultResponse<fhir.OperationOutcome | fhir.Organization>>(url).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Organization>>(`readOrganization id=${id}`))
    );
  }

  /** PUT: update on the server */
  update(arg: fhir.Organization): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Organization>> {
    const url = `${this.apiUrl}/${arg.id}`;
    return this.http.put<DefaultResponse<fhir.Organization>>(url, arg, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.Organization>>('updateOrganization'))
    );
  }

  /** PATCH: update on the server */
  patch(id: string, patch: any): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Organization>> {
    const url = `${this.apiUrl}/${id}`;
    return this.http.patch<DefaultResponse<fhir.Organization>>(url, patch, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.Organization>>('patchOrganization'))
    );
  }

  /** DELETE: delete from the server */
  delete(arg: fhir.Organization | string): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Organization>> {
    const id = typeof arg === 'string' ? arg : arg.id;
    const url = `${this.apiUrl}/${id}`;

    return this.http.delete<DefaultResponse<fhir.OperationOutcome | fhir.Organization>>(url, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Organization>>('deleteOrganization'))
    );
  }

  /* GET all or containing search params */
  search(searchParams?: any): Observable<DefaultResponse<fhir.OperationOutcome | fhir.Bundle & { type: 'searchset' } | any>> {
    const url = searchParams ? `${this.apiUrl}/?searchParams=${JSON.stringify(searchParams)}` : this.apiUrl;
    return this.http.get<DefaultResponse<fhir.OperationOutcome | fhir.Bundle & { type: 'searchset' }>>(url).pipe(
      catchError(this.handleError<DefaultResponse<fhir.OperationOutcome | fhir.Bundle & { type: 'searchset' }>>('searchOrganizations'))
    );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
