import { NbAuthOAuth2JWTToken } from '@nebular/auth';

/*export const environment = {
  production: false,
  auth: {
    endpoints: {
      base: '/auth/',
      register: 'register',
      login: 'login',
      logout: 'logout',
      refreshToken: 'refresh-token',
      requestPass: 'request-pass',
      resetPass: 'reset-pass',
    },
    token: {
      class: NbAuthOAuth2JWTToken,
      key: 'data', // this parameter tells auth mechanism where to look for the token
    }
  },
  api: {
    endpoints: {
      base: '/api/',
    },
  },
  dateFormat: 'yyyy-MM-dd',
  agmConfig: {
    apiKey: 'AIzaSyDi_YUKMamkR9m2luD88jzl68WKRedHG3Y',
    libraries: ['places'],
  },
};*/

export const environment = {
  production: false,
  auth: {
    endpoints: {
      base: '/auth/',
      register: 'register',
      login: 'login',
      logout: 'logout',
      refreshToken: 'refresh-token',
      requestPass: 'request-pass',
      resetPass: 'reset-pass',
    },
    token: {
      class: NbAuthOAuth2JWTToken,
      key: 'data', // this parameter tells auth mechanism where to look for the token
    }
  },
  api: {
    endpoints: {
      base: '/api/',
    },
  },
  dateFormat: 'yyyy-MM-dd',
  agmConfig: {
    apiKey: 'AIzaSyDi_YUKMamkR9m2luD88jzl68WKRedHG3Y',
    libraries: ['places'],
  },
};