import { Component } from '@angular/core';
import { FormlyFieldSelect } from '@ngx-formly/bootstrap';
@Component({
  selector: 'ngx-formly-field-select',
  template: `
    <ng-container *ngIf="to.type==='ng-select'; else defaultSelect">
      <ng-select *ngIf="to.multiple; else singleSelect"
        [multiple]="true"
        [items]="to.options | formlySelectOptions:field | async"
        bindLabel="label"
        bindValue="value"
        groupBy="group"
        [closeOnSelect]="false"
        virtualScroll="true"
        appendTo="body"
        [class.ng-has-value]="formControl.value"
        [formControl]="formControl"
        [compareWith]="to.compareWith || compareWith"
        [class.custom-select]="to.customSelect"
        [class.is-invalid]="showError"
        [formlyAttributes]="field">
        <ng-template ng-label-tmp let-item="item" let-clear="clear">
          <span class="ng-value-label">{{ item.label }}</span>
          <span class="ng-value-icon right" (click)="clear(item)" aria-hidden="true">×</span>
        </ng-template>
        <ng-template ng-optgroup-tmp let-item="item">
          {{ item.label }}
        </ng-template>
        <ng-template ng-option-tmp let-item="item">
          {{ item.label }}
        </ng-template>
      </ng-select>

      <ng-template #singleSelect>
        <ng-select
          [items]="to.options | formlySelectOptions:field | async"
          bindLabel="label"
          bindValue="value"
          groupBy="group"
          virtualScroll="true"
          appendTo="body"
          [class.ng-has-value]="formControl.value"
          [formControl]="formControl"
          [compareWith]="to.compareWith || compareWith"
          [class.custom-select]="to.customSelect"
          [class.is-invalid]="showError"
          [formlyAttributes]="field">
          <ng-template ng-label-tmp let-item="item">
            {{ item.label }}
          </ng-template>
          <ng-template ng-optgroup-tmp let-item="item">
            {{ item.label }}
          </ng-template>
          <ng-template ng-option-tmp let-item="item">
            {{ item.label }}
          </ng-template>
        </ng-select>
      </ng-template>
    </ng-container>
    <ng-template #defaultSelect>
      <select *ngIf="to.multiple; else singleSelect" class="form-control"
        multiple
        [class.custom-select]="to.customSelect"
        [formControl]="formControl"
        [compareWith]="to.compareWith || compareWith"
        [class.is-invalid]="showError"
        [formlyAttributes]="field">
        <ng-container *ngIf="to.options | formlySelectOptions:field | async as opts">
          <ng-container *ngIf="to._flatOptions else grouplist">
            <ng-container *ngFor="let opt of opts">
              <option [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
            </ng-container>
          </ng-container>

          <ng-template #grouplist>
            <ng-container *ngFor="let opt of opts">
              <option *ngIf="!opt.group else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
              <ng-template #optgroup>
                <optgroup [label]="opt.label">
                  <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                    {{ child.label }}
                  </option>
                </optgroup>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-container>
      </select>

      <ng-template #singleSelect>
        <select class="form-control"
          [formControl]="formControl"
          [compareWith]="to.compareWith || compareWith"
          [class.custom-select]="to.customSelect"
          [class.is-invalid]="showError"
          [formlyAttributes]="field">
          <option *ngIf="to.placeholder" [ngValue]="null">{{ to.placeholder }}</option>
          <ng-container *ngIf="to.options | formlySelectOptions:field | async as opts">
            <ng-container *ngIf="to._flatOptions else grouplist">
              <ng-container *ngFor="let opt of opts">
                <option [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
              </ng-container>
            </ng-container>

            <ng-template #grouplist>
              <ng-container *ngFor="let opt of opts">
                <option *ngIf="!opt.group else optgroup" [ngValue]="opt.value" [disabled]="opt.disabled">{{ opt.label }}</option>
                <ng-template #optgroup>
                  <optgroup [label]="opt.label">
                    <option *ngFor="let child of opt.group" [ngValue]="child.value" [disabled]="child.disabled">
                      {{ child.label }}
                    </option>
                  </optgroup>
                </ng-template>
              </ng-container>
            </ng-template>
          </ng-container>
        </select>
      </ng-template>
    </ng-template>
 `,
  styles: []
})
export class FormlyFieldSelectComponent extends FormlyFieldSelect {

}
