export enum DataTypeAccess {
  // MedicalRecordToBiobank = 1,
  // MedicalRecordToResearch,
  // BiospecimenToResearch,
  DisorderToBiobank=1,
  DisorderToHospital,
  DisorderToResearch,
  ResultsToBiobank,
  ResultsToHospital,
}
