import { Injectable } from '@angular/core';

@Injectable()
export class FhirService {

  constructor() { }

  getExtensionValueByUrl(resource: fhir.DomainResource, url: string, valueKey: string) {
    let value;
    if (resource.extension && resource.extension.length) {
      let i = 0;
      const l = resource.extension.length;
      for (i; i < l; i++) {
        if (resource.extension[i].url === url) {
          value = resource.extension[i][valueKey];
          break;
        }
      }
    }
    return value;
  }

  getIdentifierValueBySystem(resource: fhir.Patient | fhir.Organization, system: string) {
    let value;
    if (resource.identifier && resource.identifier.length) {
      let i = 0;
      const l = resource.identifier.length;
      for (i; i < l; i++) {
        if (resource.identifier[i].system === system) {
          value = resource.identifier[i].value;
          break;
        }
      }
    }
    return value;
  }

  getAddressByUse(resource: fhir.Patient | fhir.Organization, use: string) {
    let address;
    if (resource.address && resource.address.length) {
      let i = 0;
      const l = resource.address.length;
      for (i; i < l; i++) {
        if (resource.address[i].use === use) {
          address = resource.address[i];
          break;
        }
      }
    }
    return address;
  }

  getContactPointBySystem(resource: fhir.Patient | fhir.Organization, system: string) {
    let contactPoint;
    if (resource.telecom && resource.telecom.length) {
      let i = 0;
      const l = resource.telecom.length;
      for (i; i < l; i++) {
        if (resource.telecom[i].system === system) {
          contactPoint = resource.telecom[i];
          break;
        }
      }
    }
    return contactPoint;
  }

  getPatientNameByUse(resource: fhir.Patient, use: string) {
    let name;
    if (resource.name && resource.name.length) {
      let i = 0;
      const l = resource.name.length;
      for (i; i < l; i++) {
        if (resource.name[i].use === use) {
          name = resource.name[i];
          break;
        }
      }
    }
    return name;
  }

  getTypeCodeByCodingSystem(resource: fhir.Organization, system: string) {
    if (resource.type && resource.type.length) {
      let i = 0;
      const l = resource.type.length;
      for (i; i < l; i++) {
        const coding: Array<fhir.Coding> = resource.type[i].coding;
        if (coding && coding.length) {
          let j = 0;
          const m = coding.length;
          for (j; j < m; j++) {
            if (coding[j].system === system) {
              return coding[i].code;
            }
          }
        }
      }
    }
    return null;
  }

}
