import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { of } from 'rxjs/observable/of';
import { catchError } from 'rxjs/operators';

import { environment } from '@env/environment';
import { DefaultResponse } from '../interface/DefaultResponse';
import { User } from '../model/user';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable()
export class UserService {

  private apiUrl = environment.api.endpoints.base + 'user';  // URL to web api

  constructor(private http: HttpClient) { }

  /** POST: add new to the server */
  create(data: User): Observable<DefaultResponse<User>> {
    return this.http.post<DefaultResponse<User>>(this.apiUrl, data, httpOptions).pipe(
      catchError(this.handleError<DefaultResponse<User>>('createUser'))
    );
  }

  /** PUT: update user */
  public update(data: any): Observable<DefaultResponse<User>> {
    return this.http.put<DefaultResponse<User>>(this.apiUrl, data, httpOptions)
      .pipe(
        catchError(this.handleError<DefaultResponse<User>>('updateUser'))
      );
  }

  /** GET all from the server */
  public getAll(query?: string): Observable<DefaultResponse<User[]>> {
    let url = this.apiUrl;
    if (query) {
      url = `${this.apiUrl}?${query}`;
    }
    return this.http.get<DefaultResponse<User[]>>(url)
      .pipe(
        catchError(this.handleError<DefaultResponse<User[]>>('getUsers')),
      );
  }

  /**
   * Handle Http operation that failed.
   * Let the app continue.
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }
}
