import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpRequest, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NbAuthModule, NbPasswordAuthStrategy, NbAuthJWTInterceptor, NB_AUTH_TOKEN_INTERCEPTOR_FILTER } from '@nebular/auth';
import { NbSecurityModule, NbRoleProvider } from '@nebular/security';
import { NbThemeService } from '@nebular/theme';
import { environment } from '@env/environment';
import { AuthGuard } from './guard/auth-guard.service';
import { RoleProvider } from './security/role.provider';
import { Role } from './enum/Role';
import {
  UserService,
  PatientService,
  SendmailService,
  OrganizationService,
  AppointmentService,
  AllergyIntoleranceService,
  SpecimenService,
  ObservationService,
  ProcedureService,
  MedicationService,
  ImagingService,
} from './data';

import { throwIfAlreadyLoaded } from './module-import-guard';
import {
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
  TokenService,
  FhirService,
  EnumService,
} from './utils';
import { UserData } from './data/users';
import { ElectricityData } from './data/electricity';
import { SmartTableData } from './data/smart-table';
import { UserActivityData } from './data/user-activity';
import { OrdersChartData } from './data/orders-chart';
import { ProfitChartData } from './data/profit-chart';
import { TrafficListData } from './data/traffic-list';
import { EarningData } from './data/earning';
import { OrdersProfitChartData } from './data/orders-profit-chart';
import { TrafficBarData } from './data/traffic-bar';
import { ProfitBarAnimationChartData } from './data/profit-bar-animation-chart';
import { TemperatureHumidityData } from './data/temperature-humidity';
import { SolarData } from './data/solar';
import { TrafficChartData } from './data/traffic-chart';
import { StatsBarData } from './data/stats-bar';
import { CountryOrderData } from './data/country-order';
import { StatsProgressBarData } from './data/stats-progress-bar';
import { VisitorsAnalyticsData } from './data/visitors-analytics';
import { SecurityCamerasData } from './data/security-cameras';
import { CaloriesData } from './data/calories';

import { UserService as UserServiceMock } from './mock/users.service';
import { ElectricityService } from './mock/electricity.service';
import { SmartTableService } from './mock/smart-table.service';
import { UserActivityService } from './mock/user-activity.service';
import { OrdersChartService } from './mock/orders-chart.service';
import { ProfitChartService } from './mock/profit-chart.service';
import { TrafficListService } from './mock/traffic-list.service';
import { EarningService } from './mock/earning.service';
import { OrdersProfitChartService } from './mock/orders-profit-chart.service';
import { TrafficBarService } from './mock/traffic-bar.service';
import { ProfitBarAnimationChartService } from './mock/profit-bar-animation-chart.service';
import { TemperatureHumidityService } from './mock/temperature-humidity.service';
import { SolarService } from './mock/solar.service';
import { TrafficChartService } from './mock/traffic-chart.service';
import { StatsBarService } from './mock/stats-bar.service';
import { CountryOrderService } from './mock/country-order.service';
import { StatsProgressBarService } from './mock/stats-progress-bar.service';
import { VisitorsAnalyticsService } from './mock/visitors-analytics.service';
import { SecurityCamerasService } from './mock/security-cameras.service';
import { CaloriesService } from './mock/calories.service';
import { MockDataModule } from './mock/mock-data.module';

// const socialLinks = [
//   {
//     url: 'https://github.com/akveo/nebular',
//     target: '_blank',
//     icon: 'socicon-github',
//   },
//   {
//     url: 'https://www.facebook.com/akveo/',
//     target: '_blank',
//     icon: 'socicon-facebook',
//   },
//   {
//     url: 'https://twitter.com/akveo_inc',
//     target: '_blank',
//     icon: 'socicon-twitter',
//   },
// ];

const DATA_SERVICES = [
  { provide: UserService, useClass: UserService },
  { provide: PatientService, useClass: PatientService },
  { provide: SendmailService, useClass: SendmailService },
  { provide: OrganizationService, useClass: OrganizationService },
  { provide: AppointmentService, useClass: AppointmentService },
  { provide: AllergyIntoleranceService, useClass: AllergyIntoleranceService },
  { provide: SpecimenService, useClass: SpecimenService },
  { provide: ObservationService, useClass: ObservationService },
  { provide: ProcedureService, useClass: ProcedureService },
  { provide: MedicationService, useClass: MedicationService },
  { provide: ImagingService, useClass: ImagingService },
  // DEMO
  { provide: UserData, useClass: UserServiceMock },
  { provide: ElectricityData, useClass: ElectricityService },
  { provide: SmartTableData, useClass: SmartTableService },
  { provide: UserActivityData, useClass: UserActivityService },
  { provide: OrdersChartData, useClass: OrdersChartService },
  { provide: ProfitChartData, useClass: ProfitChartService },
  { provide: TrafficListData, useClass: TrafficListService },
  { provide: EarningData, useClass: EarningService },
  { provide: OrdersProfitChartData, useClass: OrdersProfitChartService },
  { provide: TrafficBarData, useClass: TrafficBarService },
  { provide: ProfitBarAnimationChartData, useClass: ProfitBarAnimationChartService },
  { provide: TemperatureHumidityData, useClass: TemperatureHumidityService },
  { provide: SolarData, useClass: SolarService },
  { provide: TrafficChartData, useClass: TrafficChartService },
  { provide: StatsBarData, useClass: StatsBarService },
  { provide: CountryOrderData, useClass: CountryOrderService },
  { provide: StatsProgressBarData, useClass: StatsProgressBarService },
  { provide: VisitorsAnalyticsData, useClass: VisitorsAnalyticsService },
  { provide: SecurityCamerasData, useClass: SecurityCamerasService },

  { provide: CaloriesData, useClass: CaloriesService },
];

const tokenInterceptorFilter = (req: HttpRequest<any>) => {
  if (req.url === environment.auth.endpoints.base + environment.auth.endpoints.register
    || req.url === environment.auth.endpoints.base + environment.auth.endpoints.login
    || req.url === environment.auth.endpoints.base + environment.auth.endpoints.refreshToken) {
    return true;
  }
  return false;
};

export const NB_CORE_PROVIDERS = [
  ...MockDataModule.forRoot().providers,
  ...DATA_SERVICES,
  ...NbAuthModule.forRoot({

    strategies: [
      NbPasswordAuthStrategy.setup({
        name: 'email',
        baseEndpoint: environment.auth.endpoints.base,
        login: {
          defaultErrors: ['login.error-description'],
          defaultMessages: ['login.success-description'],
        },
        register: {
          defaultErrors: ['register.error-description'],
          defaultMessages: ['register.success-description'],
        },
        logout: {
          defaultErrors: ['logout.error-description'],
          defaultMessages: ['logout.success-description'],
        },
        requestPass: {
          defaultErrors: ['request-password.error-description'],
          defaultMessages: ['request-password.success-description'],
        },
        resetPass: {
          resetPasswordTokenKey: 'token',
          requireValidToken: false,
          defaultErrors: ['reset-password.error-description'],
          defaultMessages: ['reset-password.success-description'],
        },
        token: environment.auth.token
      }),
    ],
    forms: {
      login: {
        redirectDelay: 0,
      },
      logout: {
        redirectDelay: 0,
      },
    },
  }).providers,

  NbSecurityModule.forRoot({
    accessControl: {
      guest: {
        view: [Role.Guest],
      },
      user: {
        parent: Role.Guest,
        view: [Role.User],
      },
      admin: {
        parent: Role.User,
        view: [Role.Admin],
        // create: '*',
        // edit: '*',
        // remove: '*',
      },
      patient: {
        parent: Role.User,
        view: [Role.Patient],
      },
      hospital: {
        parent: Role.User,
        view: [Role.HealthcareProvider],
        create: [Role.Patient],
        edit: [Role.Patient],
      },
      biobank: {
        parent: Role.User,
        view: [Role.Biobank],
        edit: [Role.Patient],
      },
      researcher: {
        parent: Role.User,
        view: [Role.Researcher],
      },
    },
  }).providers,

  {
    provide: NbRoleProvider, useClass: RoleProvider,
  },
  AuthGuard,
  AnalyticsService,
  LayoutService,
  PlayerService,
  StateService,
  TokenService,
  FhirService,
  EnumService,

  { provide: HTTP_INTERCEPTORS, useClass: NbAuthJWTInterceptor, multi: true },
  {
    provide: NB_AUTH_TOKEN_INTERCEPTOR_FILTER,
    useValue: tokenInterceptorFilter,
  },
];

@NgModule({
  imports: [
    CommonModule,
  ],
  exports: [
    NbAuthModule,
  ],
  declarations: [],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreModule, private themeService: NbThemeService) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    if (localStorage.getItem('theme_key')) {
      this.themeService.changeTheme(localStorage.getItem('theme_key'));
    }
  }

  static forRoot(): ModuleWithProviders {
    return {
      ngModule: CoreModule,
      providers: [
        ...NB_CORE_PROVIDERS,
      ],
    } as ModuleWithProviders;
  }
}
