export enum SpecimenCollectionMethod {
  Aspiration = '119295008',
  Bioptics = '413651001',
  Extirpation = '360020006',
  MidstreamUrine = '430823004',
  Induced = '16404004',
  Irrigation = '67889009',
  Autopsy = '29240004',
  Sputum = '45710003',
  Punctate = '7800008',
  Scrapings = '258431006',
  Blushing = '20255002',
  Smear = '386147002',
  Fingerstick = '278450005',
}
// system: http://snomed.info/sct
