import { Injectable } from '@angular/core';
import { PeriodsService } from './periods.service';
import { OrdersChart, OrdersChartData } from '../data/orders-chart';

@Injectable()
export class OrdersChartService extends OrdersChartData {

  private year = [
    '2012',
    '2013',
    '2014',
    '2015',
    '2016',
    '2017',
    '2018',
    '2019',
  ];

  private data = {};

  constructor(private period: PeriodsService) {
    super();
    this.data = {
      week: this.getDataForWeekPeriod(),
      month: this.getDataForMonthPeriod(),
      year: this.getDataForYearPeriod(),
    };
  }

  private getDataForWeekPeriod(): OrdersChart {
    return {
      chartLabel: this.getDataLabels(42, this.period.getWeeks()),
      linesData: [
        [
          184, 167, 126, 166, 189, 149,
          142, 171, 140, 104, 165, 127,
          141, 158, 130, 108, 155, 151, 167,
          109, 125, 144, 166, 189, 112,
          136, 159, 180, 100, 116, 129,
          138, 142, 139, 129, 112, 138,
          158, 121, 178, 128, 71,
        ],
        [
          28, 37, 22, 51, 28, 12,
          23, 24, 11, 88, 57, 22,
          87, 54, 24, 10, 81, 68, 61,
          58, 61, 69, 40, 56, 15, 37,
          61, 86, 10, 33, 54, 71,
          84, 43, 27, 27, 27, 27,
          27, 27, 27, 27, 27,
        ],
        [
          58, 78, 93, 105, 112, 113,
          104, 90, 10, 73, 68, 64,
          62, 60, 59, 58, 59, 66,
          79, 95, 115, 136, 57, 76,
          92, 101, 104, 103, 100, 93,
          84, 73, 162, 151, 141, 134,
          132, 132, 132, 132, 132, 132,
        ],
      ],
    };
  }

  private getDataForMonthPeriod(): OrdersChart {
    return {
      chartLabel: this.getDataLabels(47, this.period.getMonths()),
      linesData: [
        [
          115, 163, 113, 156, 184, 167,
          141, 158, 130, 108, 155, 151,
          109, 125, 144, 166, 189, 112,
          136, 159, 180, 100, 116, 129,
          164, 171, 157, 151, 150,
          152, 155, 160, 166, 170,
          167, 153, 135, 115, 97,
          82, 171, 164, 163, 162, 161,
          162, 165, 173, 184, 102,
          127, 159, 103, 159, 133,
        ],
        [
          38, 38, 35, 22, 24,
          21, 21, 23, 27, 21,
          22, 29, 12, 12, 12,
          12, 16, 12, 10, 20, 21,
          34, 38, 33, 49, 48, 24, 26,
          34, 14, 15, 12, 16, 16,
          12, 14, 12, 17, 19, 19,
          16, 11, 15, 36, 27, 35, 22,
        ],
        [
          116, 143, 148, 150, 140,
          165, 173, 159, 111,
          122, 155, 130, 128, 136,
          150, 168, 188, 109, 129,
          146, 158, 163, 165,
          173, 187, 108, 136,
          171, 110, 146, 175,
          193, 100, 158, 187,
          168, 141, 109, 175,
          143, 120, 106, 102,
          107, 122, 147, 186, 148,
        ],
      ],
    };
  }

  private getDataForYearPeriod(): OrdersChart {
    return {
      chartLabel: this.getDataLabels(42, this.year),
      linesData: [
        [
          190, 169, 127, 166, 189, 198,
          196, 187, 175, 159, 143, 127,
          112, 198, 186, 176, 170, 168,
          165, 158, 147, 134, 120, 104,
          188, 172, 157, 142, 128, 116,
          106, 139, 105, 104, 102, 189, 184,
          177, 169, 160, 149, 136, 122,
        ],
        [
          9, 15, 26, 28, 24, 26,
          16, 19, 29, 29, 23, 37,
          39, 35, 35, 36, 38, 35,
          31, 30, 25, 21, 17, 11,
          10, 14, 12, 11, 19, 154,
          16, 15, 12, 18, 36, 27,
          23, 22, 22, 22, 22, 22, 22,
        ],
        [
          65, 107, 87, 59, 75, 86,
          93, 97, 99, 97, 90, 79,
          65, 67, 66, 75, 82, 61,
          81, 93, 68, 97, 90, 187,
          85, 81, 72, 60, 45, 26,
          95, 82, 70, 60, 66, 79, 72,
          63, 82, 91, 92, 81,
        ],
      ],
    };
  }

  getDataLabels(nPoints: number, labelsArray: string[]): string[] {
    const labelsArrayLength = labelsArray.length;
    const step = Math.round(nPoints / labelsArrayLength);

    return Array.from(Array(nPoints)).map((item, index) => {
      const dataIndex = Math.round(index / step);

      return index % step === 0 ? labelsArray[dataIndex] : '';
    });
  }

  getOrdersChartData(period: string): OrdersChart {
    return this.data[period];
  }
}
