import { Injectable } from '@angular/core';
import { of as observableOf, Observable } from 'rxjs';
import { Calories, CaloriesChart, CaloriesData } from '../data/calories';
import { PeriodsService } from './periods.service';

@Injectable()
export class CaloriesService extends CaloriesData {

  private listData: Calories[] = [
    {
      title: 'Prev week',
      days: [
        { day: 'Mon', delta: '1.56', down: true, kCals: '789' },
        { day: 'Tue', delta: '0.33', down: false, kCals: '791' },
        { day: 'Wed', delta: '0.62', down: true, kCals: '790' },
        { day: 'Thu', delta: '1.93', down: true, kCals: '783' },
        { day: 'Fri', delta: '2.52', down: true, kCals: '771' },
        { day: 'Sat', delta: '0.39', down: false, kCals: '774' },
        { day: 'Sun', delta: '1.61', down: true, kCals: '767' },
      ],
    },
    {
      title: 'Last week',
      active: true,
      days: [
        { day: 'Mon', delta: '1.34', down: true, kCals: '1300' },
        { day: 'Tue', delta: '0.95', down: true, kCals: '1200' },
        { day: 'Wed', delta: '0.45', down: false, kCals: '1650' },
        { day: 'Thu', delta: '0.32', down: false, kCals: '1800' },
        { day: 'Fri', delta: '0.12', down: false, kCals: '1900' },
        { day: 'Sat', delta: '1.72', down: false, kCals: '2850' },
        { day: 'Sun', delta: '0.78', down: true, kCals: '2500' },
      ],
    },
  ];

  private chartPoints = [
    1300, 1200, 1650, 1800,
    1900, 2850, 2500,
  ];

  private days = this.period.getWeeks();

  chartData: CaloriesChart[];

  constructor(private period: PeriodsService) {
    super();
    this.chartData = this.chartPoints.map((p, index) => ({
      label: `${this.days[index]}`,
      value: p,
    }));
  }

  getListData(): Observable<Calories[]> {
    return observableOf(this.listData);
  }

  getChartData(): Observable<CaloriesChart[]> {
    return observableOf(this.chartData);
  }
}
