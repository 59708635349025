import { Observable } from 'rxjs';

export interface Month {
  day: string;
  delta: string;
  down: boolean;
  kCals: string;
}

export interface Calories {
  title: string;
  active?: boolean;
  days: Month[];
}

export interface CaloriesChart {
  label: string;
  value: number;
}

export abstract class CaloriesData {
  abstract getListData(): Observable<Calories[]>;
  abstract getChartData(): Observable<CaloriesChart[]>;
}
