import { Component, Input } from '@angular/core';

import { ViewCell } from 'ng2-smart-table';

@Component({
  template: `
    {{value | date:'dd MMM yyyy HH:mm:ss'}}
  `,
})
export class CellDateTimeRenderComponent implements ViewCell {

  renderValue: string;

  @Input() value: any;
  @Input() rowData: any;

}
